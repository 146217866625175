<template>
  <v-row justify="center">
    <v-dialog
        v-model="showDialog"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $tc('Customer', 1) }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="userForm" lazy-validation>
            <v-container>
              <v-row>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                  <v-text-field
                      v-model="user.firstName"
                      :label="$t('firstName')"
                      required
                      :rules="requiredRule('Vorname')"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                  <v-text-field
                      v-model="user.lastName"
                      :label="$t('lastName')"
                      required
                      :rules="requiredRule('Nachname')"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                >
                  <v-text-field
                      v-model="user.companyName"
                      :label="$t('Company name (optional)')"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                >
                  <v-text-field
                      v-model="user.customerNumber"
                      :label="$t('Customer number (optional)')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="user.email"
                      label="E-Mail"
                      required
                      :rules="emailRules()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      v-model="user.password"
                      :label="$t('Password')"
                      :hint="$t('If no password os entered it will be generated automatically')"
                      required
                      :type="showPassword ? 'text' : 'password'"
                      @click:append="showPassword = !showPassword"
                      @blur="showPassword=false"
                      :rules="passwordRules"
                  ></v-text-field>
                </v-col>
                <v-col v-if="hasRole('admin')" cols="12">
                  <v-select v-model="user.role" :items="roles" :label="$t('Role')"></v-select>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                      v-model="user.badges"
                      :items="badges"
                      filled
                      chips
                      :label="$t('Badges')"
                      item-text="name"
                      item-value="id"
                      multiple
                  >
                    <template v-slot:selection="data">
                      <v-chip
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          close
                          @click="data.select"
                          @click:close="removeBadge(data.item)"
                      >
                        <img class="badge-image badge-image badge-image-small" v-if="data.item.image" :src="getImageUrl(data.item.image.path)">
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-content class="ma-0">
                        <v-list-item-title>
                          <v-row>
                            <v-col cols="6">{{data.item.name}}</v-col>
                            <v-col cols="6"><img class="badge-image" v-if="data.item.image" :src="getImageUrl(data.item.image.path)"></v-col>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            {{ $t('Cancel') }}
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveUser"
          >
            {{ user.id ? $t('Save') : $t('Add')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm-dialog ref="confirmDialog"/>
    <snackbar ref="snackbar"></snackbar>
  </v-row>
</template>

<script>
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/ConfirmDialog'
import Snackbar from '@/components/Snackbar'
import store from "@/store";
import {mapGetters} from "vuex";
export default {
  name: 'UserEditDialog',
  components: { Snackbar, ConfirmDialog },
  data (){ return {
    badges: [],
    isEmailTaken: false,
    isLoginTaken: false,
    oldEmail: undefined,
    passwordRules: [
      value => (!value || value  && value.length >= 8) || this.$t('The password must have at least 8 characters'),
      value => (!value || value && !!value.match(/\d/) && !!value.match(/[a-zA-Z]/)) || this.$t('The password hast to contain at least one character and one number')
    ],
    showDialog: false,
    showPassword: false,
    snackbarSuccessMail: {
      text: this.$t('User saved successfully')+". "+this.$t('An E-Mail was sent to the created user.'),
      icon: 'mdi-check-circle',
      color: 'success',
      timeout: 6000,
    },
    snackbarSuccess: {
      text: this.$t('User saved successfully'),
      icon: 'mdi-check-circle',
      color: 'success'
    },
    roles: [
      { text: this.$t('Administrator'), value: 'admin'},
      { text: this.$t('Distribution'), value: 'sale' },
      { text: this.$t('Author'), value: 'author' },
      { text: this.$t('Partner'), value: 'partner' },
      { text: this.$t('User'), value: 'user' },
    ],
    user: {},
    events: []
  }},
  methods: {
    async show(user) {
      this.user = {...user, role: user ? user.role : 'user'}
      this.badges = await APIService.getBadges()
      this.oldEmail = user?.email
      this.showDialog = true
    },
    close () {
      this.showDialog = false
      this.user = {}
      this.$refs.userForm.resetValidation()
    },
    getImageUrl(path) {
      return `${process.env.VUE_APP_ROOT_API}/content/${path}`
    },
    async saveUser() {
      if (!this.$refs.userForm.validate()) {
        return
      }
      if((!this.user.id || this.isEmailChanged()) && await APIService.isEmailTaken(this.user.email)) {

        this.isEmailTaken = true
        return
      }
      if (this.user.id) {
        APIService.updateUser(this.user).then(() => {
          store.dispatch('fetchUsers')
          this.close()
          this.$refs.snackbar.show(this.snackbarSuccess)
        })
      } else {
        APIService.saveUser(this.user).then(() => {
          store.dispatch('fetchUsers')
          this.close()
          this.$refs.snackbar.show(this.snackbarSuccessMail)
        })
      }
    },
    isEmailChanged() {
      return this.user.email !== this.oldEmail
    },
    requiredRule (fieldName) {
      return [value => !!value || `${fieldName} `+this.$t('mandatory')]
    },
    removeBadge(item) {
      const index = this.user.badges.indexOf(item.id)
      if (index >= 0) this.user.badges.splice(index, 1)
    },
    emailRules() {
      const validEmail = (value) => {
        // E-Mail Validierungsregel
        const emailRegx = /\S+@\S+\.\S+/
        return emailRegx.test(value) || this.$t('Invalid E-Mail')
      }
      return [
        value => !!value || this.$t('E-Mail is mandatory'),
        validEmail,
        !this.isEmailTaken || this.$t('E-Mail already taken')
      ]
    },
  },
  computed: {
    ...mapGetters(['hasRole'])
  },
  watch: {
    "user.email": function() {
      this.isEmailTaken = false
    },
  }
}
</script>

<style scoped>
.badge-image {
  max-width: 100px;
  padding: 4px;
}
.badge-image-small {
  max-width: 50px;
}
</style>
