<template>
  <v-row justify="center">
    <v-dialog
        v-model="showDialog"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $tc('Group', 1) }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="groupForm" lazy-validation>
            <v-container>
              <v-row>
                <v-col>
                ID: {{ this.group.id }}
                </v-col>
              </v-row>
              <v-row>
                <v-col
                    cols="12"
                >
                  <v-text-field
                      v-model="group.name"
                      :label="$t('Name')"
                      required
                      :rules="requiredRule($t('Name'))"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                >
                  <v-text-field
                      v-model="group.description"
                      :label="$t('Description')"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                >
                  <v-select
                      v-model="group.startPage"
                      :items="[{text: $t('courses'), value: 'courses'}, {text: $t('Favorites'), value: 'favorites'}]"
                      :label="$t('Start Page')"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <user-select
                      :value.sync="group.members"
                      :users="teamMembers"
                      :label="$t('Group members')"
                      multiple
                      @removeItem="removeUser"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <favorites-lists-select
                      v-if="groupExists && hasAccess"
                      :value.sync="groupFavoritesListsIds"
                      :favorites-lists="favotitesLists"
                      :label="$t('Group Favorite Lists')"
                      multiple
                      @removeItem="removeFavoriteList"
                      @addItem="addFavoriteList"
                  />
                  <div v-if="!groupExists">
                    {{$t('You have to save this new group to be able to assign Group Favorite Lists')}}
                  </div>
                  <div v-if="!hasAccess">
                    {{$t('You have to be part of the group to be able to access Group Favorites')}}
                  </div>
                </v-col>
              </v-row>

            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            {{ $t('Cancel') }}
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveGroup"
          >
            {{ group.id ? $t('Save') : $t('Add') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm-dialog ref="confirmDialog"/>
    <my-snack-bar ref="mySnackbar"></my-snack-bar>
  </v-row>
</template>

<script>
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/ConfirmDialog'
import MySnackBar from "@/components/common/MySnackBar";
import UserSelect from "@/components/UserSelect";
//import APIServiceRestDB from "@/services/APIServiceRestDB";
import FavoritesListsSelect from "@/components/FavoritesListsSelect.vue";
import {mapState} from "vuex";
const INITIAL_GROUP_OBJECT = { members: [] };
export default {
  name: 'GroupEditDialog',
  components: { ConfirmDialog, MySnackBar, UserSelect, FavoritesListsSelect },
  props: ['userId'],
  data (){ return {
    group: {},
    favotitesLists: [],
    groupFavoritesLists: [],
    groupFavoritesListsIds: [],
    origGroupFavoriteListsIds: [],
    teamMembers: [],
    showDialog: false,
    hasAccess: false,
    groupExists: false
  }},
  computed:{
    ...mapState(['user']),
  },
  methods: {
    async show(group) {

      console.log(group);

      this.group =  group ? { ...group } : { ...INITIAL_GROUP_OBJECT }
      this.teamMembers = await APIService.getTeamMembers( this.userId)
      this.favotitesLists = await APIService.getFavoritesListsUser(this.userId)
      /*for(let i = 0; i < this.favotitesLists.length; i++){
        this.favotitesLists[i].id = this.favotitesLists[i].id;
      }*/
      this.groupFavoritesListsIds = [];
      this.groupFavoritesLists = [];
      if(this.group.id != undefined) {
        this.groupExists = true;
        this.groupFavoritesLists = await APIService.getFavoritesListsGroup(this.group.id)
        if(!this.groupFavoritesLists){
          this.groupFavoritesLists = [];
        }else{
          this.hasAccess = true;
        }
        for (let i = 0; i < this.groupFavoritesLists.length; i++) {
          if (!this.groupFavoritesLists[i].origFavoriteList || this.groupFavoritesLists[i].origFavoriteList == undefined) {
            continue;
          }
          this.groupFavoritesListsIds.push(this.groupFavoritesLists[i].origFavoriteList);
        }
        this.origGroupFavoriteListsIds = JSON.parse(JSON.stringify(this.groupFavoritesListsIds));
      }
      this.favotitesLists.shift();
      console.log(this.favotitesLists);
      this.showDialog = true
    },
    close () {
      this.showDialog = false
      this.group =  { ...INITIAL_GROUP_OBJECT }
      this.$refs.groupForm.resetValidation()
    },
    async saveGroup () {
      if (!this.$refs.groupForm.validate()) {
        return
      }
      if (this.group.id) {
        APIService.updateGroup(this.group).then(() => {
          this.$emit('updated')
          this.close()
          this.$refs.mySnackbar.success(this.$t('Group updated successfully'))
        })
      } else {
        APIService.saveGroup({...this.group, owner: this.userId}).then(() => {
          this.$emit('updated')
          this.close()
          this.$refs.mySnackbar.success(this.$t('Group saved successfully'))

        })
      }

    },
    requiredRule (fieldName) {
      return [value => !!value || `${fieldName} `+ this.$t('mandatory')]
    },
    async updatedFavoriteList(items){
      for(let i=0; i<this.origGroupFavoriteListsIds.length; i++){
        let found = false;
        for(let j=0; j < items.length; j++) {
          if (this.origGroupFavoriteListsIds[i] == items[j]) {
            found = true;
          }
        }
        if(!found){
          for(let j = 0; j < this.groupFavoritesLists.length; j++){
            if(this.groupFavoritesLists[j].origFavoriteList == this.origGroupFavoriteListsIds[i]){
              console.log("found now delete "+this.groupFavoritesLists[i].id);
              await APIService.deleteFavoritesList(this.groupFavoritesLists[i].id);
            }
          }
          const index = this.origGroupFavoriteListsIds.indexOf(this.origGroupFavoriteListsIds[i]);
          this.origGroupFavoriteListsIds.splice(index, 1);
        }
      }
    },
    async addFavoriteList(item){
      console.log("add fav list");
      console.log(this.user);
      console.log(this.userId);
      let list = JSON.parse(JSON.stringify(item));
      delete list.user;
      delete list.id;
      list.user = this.user.id;
      list.origFavoriteList = item.id;
      list.group = this.group.id;
      console.log(list);
      let ret = await APIService.createFavoritesLists(list);
      this.groupFavoritesLists = await APIService.getFavoritesListsGroup(this.group.id)
      console.log(ret);
      this.origGroupFavoriteListsIds.push(item.id);
    },
    async removeFavoriteList(item) {
      console.log("remove "+item.title+" id: "+item.id);
      for(let i = 0; i < this.groupFavoritesLists.length; i++){
        console.log(this.groupFavoritesLists[i]);
        if(this.groupFavoritesLists[i].origFavoriteList == item.id){
          console.log("found");
          await APIService.deleteFavoritesList(this.groupFavoritesLists[i].id);
        }
      }
      const index = this.groupFavoritesListsIds.indexOf(item.id);
      this.groupFavoritesListsIds.splice(index, 1);
      //await APIServiceRestDB.deleteFavoritesList(item.id);
    },
    removeUser(item) {
      const index = this.group.members.indexOf(item.id)
      if (index >= 0) this.group.members.splice(index, 1)
    },
  },

}
</script>

<style scoped>
</style>
