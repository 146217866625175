<template>
  <v-autocomplete
      :items="favoritesLists"
      outlined
      :value="value"
      :label="label"
      item
      item-text="email"
      item-value="id"
      :filter="userFilter"
      :rules="rules"
      :multiple="multiple"
      @change="change"
  >
    <template v-slot:item="data">
      <v-list-item-content @click="$emit('addItem', data.item)">
        <v-list-item-title v-html="data.item.title"></v-list-item-title>
        <v-list-item-subtitle v-html="data.item._id"></v-list-item-subtitle>
      </v-list-item-content>
    </template>
    <template v-slot:selection="data">
      <v-chip v-if="multiple"
          v-bind="data.attrs"
          :input-value="data.selected"
          @click="data.select"
      >
        <v-avatar left>
          <v-icon @click="$emit('removeItem', data.item);">mdi-close-circle</v-icon>
        </v-avatar>
        {{data.item.title}}
      </v-chip>
      <template v-else>
        {{data.item.title}}
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
import {propertySearch} from "@/utils/searchUtils";

export default {
  name: "FavoritesListsSelect",
  props: {
    favoritesLists: {
      required: true,
    },
    value: {
      required: true,
    },
    label: {
      required: true,
      type: String
    },
    multiple: {
      required: false,
      type: Boolean,
      default: false,
    },
    rules: {
      required: false,
      type: Array ,
    }
  },
  methods: {
    change(value) {
      console.log("Val", value)
      this.$emit('update:value', value)
      this.$emit('updated', value)
    },
    userFilter(user, query) {
      return propertySearch(user, query, ['title','lastName', 'email','companyName'])
    },
  },
}
</script>

<style scoped>

</style>
