<template>
  <div>
    <h1>{{ userId === $store.state.user.id ? $tc('My Groups', 2) : $tc('Group', 1) }}</h1>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('Search')"
                single-line
                hide-details
            ></v-text-field>
          </v-col>
          <v-spacer/>
          <v-col class="text-right">
            <v-btn
                class="mr-2"
                rounded
                color="primary"
                dark
                @click="createUser"
            >
              <v-icon left>mdi-plus</v-icon>
              {{$tc('Group', 1)}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="groups"
          :search="search"
          :items-per-page="itemsPerPage"
          @update:options="updateOptions"
          class="elevation-1"
          :hide-default-footer="groups.length < 5"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="editGroup(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              @click="deleteGroup(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <group-edit-dialog ref="groupEditDialog" :userId="userId" @updated="reloadGroups"/>
    <confirm-dialog ref="confirmDelete"/>
    <my-snack-bar ref="mySnackBar"/>
  </div>
</template>

<script>
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/ConfirmDialog'
import {vuetifyTableMixin} from "@/mixins/VuetifyTableMixin";
import GroupEditDialog from "@/components/GroupEditDialog";
import MySnackBar from "@/components/common/MySnackBar";
import {mapState} from "vuex";
export default {
  name: "GroupList",
  mixins: [vuetifyTableMixin],
  components: {
    ConfirmDialog,
    GroupEditDialog,
    MySnackBar,
  },
  props: ['userId'],
  data() {
    return {
      search: '',
      groups: [],
    }
  },
  methods: {
    createUser() {
      this.$refs.groupEditDialog.show()
    },
    deleteGroup: function (group) {
      this.$refs.confirmDelete.show({
        title: this.$t('Remove group'),
        text: this.$t('Do you want to remove the following group?')+"<br><b>"+ group.name +"</b>",
        confirm: this.$t('remove')
      }).then(() => {
        APIService.deleteGroup(group.id).then(async () => {
          await this.reloadGroups();
        })
      })
    },
    editGroup (item) {
      console.log(this.user);
      this.$refs.groupEditDialog.show(item)
    },
    async reloadGroups() {
      this.groups = await APIService.getUserGroups(this.userId)
    },
  },
  computed: {
    ...mapState(['user']),
    headers(){ return [
      { text: this.$t('name'), value: 'name' },
      { text: this.$t('description'), value: 'description' },
      {
        text: this.$t('Actions'),
        value: 'actions',
        sortable: false,
        align: 'end'
      }
    ]}
  },
  async beforeRouteEnter(to, from, next) {
    const groups = await APIService.getUserGroups(to.params.userId)
    next(vm => vm.groups = groups)
  },
  async beforeRouteUpdate(to,from,next) {
    this.groups = await APIService.getUserGroups(to.params.userId)
    next()
  }

}
</script>

<style scoped>

</style>
