<template>
  <div>
    <h1>{{ $tc('Customer', 2) }}</h1>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('Search')"
                single-line
                hide-details
            ></v-text-field>
          </v-col>
          <v-spacer/>
          <v-col class="text-right">
            <v-btn
                class="mr-2"
                rounded
                color="primary"
                dark
                @click="createUser"
            >
              <v-icon left>mdi-plus</v-icon>
              {{ $tc('Customer', 1) }}
            </v-btn>
            <v-btn
                class="mr-2"
                rounded
                color="primary"
                dark
                @click="importUsers"
            >
              {{ $t('Import') }}
            </v-btn>
            <v-btn
                v-if="hasRole('admin')"
                rounded
                color="primary"
                dark
                @click="migrateUsers"
            >
              {{ $t('Migrate') }}
            </v-btn>
            <v-btn
                rounded
                color="success"
                dark
                @click="createCSV"
                style="margin-left:10px"
            >
              <v-icon left>mdi-download</v-icon>
              CSV
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
          ref="userTable"
          :headers="headers"
          :items="users"
          :search="search"
          :items-per-page="itemsPerPage"
          @update:options="updateOptions"
          class="elevation-1"
          :hide-default-footer="users.length < 5"
          @current-items="getFiltered"
          :footer-props="{
            'items-per-page-text':$t('Rows per page'),
            'items-per-page-options': [15, 50, 100, -1]
          }"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="editUser(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              class="mr-2"
              @click="deleteUser(item)"
          >
            mdi-delete
          </v-icon>
          <v-icon
              small
              @click="showLicenses(item)"
          >
            mdi-certificate
          </v-icon>
          <v-icon
              small
              @click="showPassed(item)"
          >
            mdi-check-bold
          </v-icon>
        </template>
        <template v-slot:item.role="{ item }" class="ml-0">
          <v-chip
              :color="item.role === 'admin' ?  'red' : item.role==='user' ? 'green' : 'blue'"
              class="ma-0"
              small
              style="color: white; width: 80px"
          >
            {{item.role}}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
    <user-edit-dialog ref="userEditDialog"/>
    <user-passed-dialog ref="userPassedDialog" />
    <user-license-dialog ref="userLicenseDialog" />
    <user-import-dialog ref="userImportDialog" @complete="importComplete"/>
    <user-migration-dialog ref="userMigrationDialog" @complete="migrationSuccess" @error="migrationError"/>
    <confirm-dialog ref="confirmDelete"/>
    <my-snack-bar ref="mySnackBar"/>
    <Spinner v-if="showLoader" class="s3m-spinner" name="circle" color="#008bc4" />
  </div>
</template>

<script>
import store from '@/store/index'
import UserEditDialog from '@/components/UserEditDialog'
import UserPassedDialog from "@/components/UserPassedDialog.vue";
import UserLicenseDialog from "@/components/UserLicenseDialog.vue";
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/ConfirmDialog'
import {vuetifyTableMixin} from "@/mixins/VuetifyTableMixin";
import {mapGetters, mapState} from "vuex";
import UserImportDialog from "@/components/UserImportDialog";
import UserMigrationDialog from "@/components/UserMigrationDialog";
import MySnackBar from "@/components/common/MySnackBar";
import Papa from "papaparse";

export default {
  name: "UserList",
  mixins: [vuetifyTableMixin],
  components: {
    UserEditDialog,
    UserPassedDialog,
    UserLicenseDialog,
    UserImportDialog,
    UserMigrationDialog,
    ConfirmDialog,
    MySnackBar,
  },
  data() {
    return {
      search: '',
      filteredUsers: [],
      showLoader: false
    }
  },
  methods: {
    getFiltered(){
      this.filteredUsers = this.$refs.userTable?.['$children'][0]?.filteredItems;
    },
    createCSV(){
      let csvData = [];
      csvData.push([]);
      for(let i=0; i < this.headers.length; i++){
        csvData[0].push(this.headers[i].text);
      }

      for(let i=0; i < this.filteredUsers.length; i++){
        let current = this.filteredUsers[i];
        let row = [current ? current.firstName : "", current ? current.lastName : "", current ? current.companyName : "", current && current.customerNumber ? current.customerNumber : "", current ? current.email : "", current ? current.role : "", ""];
        csvData.push(row);
      }
      let csv = Papa.unparse(csvData);

      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = 'user.csv';
      anchor.click();
    },
    createUser () {
      this.$refs.userEditDialog.show()
    },
    editUser (item) {
      this.$refs.userEditDialog.show(item)
    },
    showPassed (item){
      this.$refs.userPassedDialog.show(item);
    },
    showLicenses (item){
      this.$refs.userLicenseDialog.show(item);
    },
    importUsers() {
      this.$refs.userImportDialog.show(this.user.id)
    },
    migrateUsers() {
      this.$refs.userMigrationDialog.show()
    },
    deleteUser: function (user) {
      this.$refs.confirmDelete.show({
        title: this.$t('Remove user'),
        text: this.$t('Do you want to remove the following user?')+" "+user.firstName + " " + user.lastName,
        confirm: this.$t('remove')
      }).then(() => {
        APIService.deleteUser(user).then(() => {
          store.dispatch('fetchUsers')
        })
      })
    },
    importComplete() {
      this.$refs.mySnackBar.success(this.$t('Users successfully imported.'))
      store.dispatch('fetchUsers')
      this.showLoader = false;
    },
    migrationSuccess() {
      store.dispatch('fetchUsers')
    },
    migrationError(error) {
      this.$refs.mySnackBar.error(this.$t('Import failed for some users') + ': ' + error.message)
      store.dispatch('fetchUsers')
    },
  },
  computed: {
    ...mapState(['user', 'users']),
    ...mapGetters(['hasRole']),
    headers(){ return [
      { text: this.$t('firstName'), value: 'firstName' },
      { text: this.$t('lastName'), value: 'lastName' },
      { text: this.$t('Company'), value: 'companyName' },
      { text: this.$t('Customer number'), value: 'customerNumber' },
      { text: 'E-Mail', value: 'email' },
      {text: this.$t('Role'), value: "role"},
      {
        text: this.$t('Actions'),
        value: 'actions',
        sortable: false,
        align: 'end'
      },
      {
        value: 'completeName',
        align: ' d-none'
      }
    ]}
  },

  mounted() {
    this.filteredUsers = this.user;
  },
  async beforeRouteEnter(to, from, next) {
    try {
      await store.dispatch('fetchUsers');
    } catch (error) {
      console.error("dispatch failed", error);
    }
    next();
  }
}
</script>

<style scoped>

</style>
