<template>
  <v-row justify="center">
    <v-dialog
        v-if="showDialog"
        v-model="showDialog"
        persistent
        width="800"
        max-width="800"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ this.$t("Import clients") }}</span>
        </v-card-title>
        <v-card-text>
          <v-file-input
              show-size
              v-model="importCSV"
              label="CSV"
              @change="readUserCSV"
          ></v-file-input>
          <a target="_blank" href="/user-import-example.csv">{{ this.$t("Sample file") }}</a><br>
          <a target="_blank" :href="this.$t('support-link')">Support</a>
          <template  v-if="importCSV">
            <v-data-table
                :headers="headers"
                :items="allUsers"
                v-model="selectedImportUsers"
                :items-per-page="-1"
                class="elevation-1"
                :hide-default-footer="true"
                show-select
                @toggle-select-all="selectAllToggle"
                item-key="email"
            >
              <template v-slot:item.password="{ item }">
                <div :class="{valid: item.invalid }">{{item.password}}{{ item.invalid ? ` (${item.invalid })` : ''}}</div>
              </template>
              <template v-slot:item.data-table-select="{ item, isSelected, select }">
                <v-simple-checkbox
                    :value="isSelected"
                    :readonly="!!item.invalid"
                    :disabled="!!item.invalid"
                    @input="select($event)"
                ></v-simple-checkbox>
              </template>
            </v-data-table>
            {{ selectedImportUsers.length === 0 ? 'Kein' :  selectedImportUsers.length }} Benutzer für Import ausgewählt
          </template>
        </v-card-text>
        <v-card-actions>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            {{ $t('Cancel') }}
          </v-btn>
          <v-btn
              :disabled="selectedImportUsers.length === 0"
              color="blue darken-1"
              text
              @click="importUsers"
          >
            Import
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <snackbar ref="snackbar"></snackbar>
  </v-row>
</template>

<script>
import Papa from 'papaparse'
import Snackbar from '@/components/Snackbar'
import {mapState} from "vuex";
import APIService from "@/services/APIService";
export default {
  name: "UserImportDialog",
  components: { Snackbar },
  data() {
    return {
      allUsers: [],
      disabledCount: 0,
      importCSV: undefined,
      passwordRules: [
        value => (!value || value && !!value.match(/\d/) && !!value.match(/[a-zA-Z]/)) || this.$t('The password hast to contain at least one character and one number'),
        value => (!value || value  && value.length >= 8) || this.$t('The password must have at least 8 characters'),
      ],
      selectedImportUsers: [],
      showDialog: false,
      userId: undefined,
    }
  },
  methods: {
    show(userId) {
      this.showDialog = true
      this.userId = userId
    },
    close() {
      this.allUsers = []
      this.selectedImportUsers = []
      this.disabledCount = 0
      this.importCSV = undefined
      this.showDialog = false
    },
    async readUserCSV() {
      const that = this

      Papa.parse(this.importCSV, {
        header: true,
        skipEmptyLines: true,
        complete(result) {
          that.allUsers = result.data.map(user => ({...user,  invalid: that.validate(user.password, that.passwordRules)}))
          that.allUsers.map(item => {
            if (item.invalid) that.disabledCount += 1
          })
        }
      })
    },
    async importUsers() {
      await APIService.importUsers(this.userId, this.selectedImportUsers)
      this.$emit('complete')
      this.close()
    },
    selectAllToggle(props) {
      console.log('selectAllToggle', props)
      if(this.selectedImportUsers.length !== this.allUsers.length - this.disabledCount) {
        console.log('selectAllToggleX', props)
        this.selectedImportUsers = [];
        const self = this;
        props.items.forEach(item => {
          if(!item.invalid) {
            self.selectedImportUsers.push(item);
          }
        });
      } else this.selectedImportUsers = [];
    },
    validate(text, rules) {
      const result = rules.reduce((prev, rule) => {
        const result = rule(text);
        return typeof result === 'string' ? result : prev
      }, '')
      console.log("RuleResult", text,  result)
      return result
    }
  },
  computed: {
    ...mapState(['options']),
    itemsPerPage() {
      return this.options.itemsPerPage
    },
    headers(){ return [
      { text: this.$t('firstName'), value: 'firstName' },
      { text: this.$t('lastName'), value: 'lastName' },
      { text: 'E-Mail', value: 'email' },
      { text: 'Passwort', value: 'password' },
    ]},
  },
}
</script>

<style scoped>
.valid {
  color: red;
}
</style>
