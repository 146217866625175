<template>
  <v-row justify="center">
    <v-dialog
        v-model="showDialog"
        persistent
        max-width="800px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $tc('Passed', 1) }}</span>
        </v-card-title>
        <v-card-text>
          <div>{{user.firstName}} {{user.lastName}}</div>
          <v-form ref="userForm" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      :label="$t('Search')"
                      single-line
                      hide-details
                  ></v-text-field>
                <v-spacer/>
                  <v-data-table
                      :headers="headers"
                      :items="passedCourses"
                      :search="search"
                      :items-per-page="25"
                      class="elevation-1"

                      ref="userTable"
                      :footer-props="{
            'items-per-page-text':$t('Rows per page'),
            'items-per-page-options': [15, 50, 100, -1]
          }"
                  >
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm-dialog ref="confirmDialog"/>
    <snackbar ref="snackbar"></snackbar>
  </v-row>
</template>

<script>
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/ConfirmDialog'
import Snackbar from '@/components/Snackbar'
import {mapGetters} from "vuex";
export default {
  name: 'UserPassedDialog',
  components: { Snackbar, ConfirmDialog },
  data (){ return {
    showDialog: false,
    search: "",
    snackbarSuccessMail: {
      text: this.$t('User saved successfully')+". "+this.$t('An E-Mail was sent to the created user.'),
      icon: 'mdi-check-circle',
      color: 'success',
      timeout: 6000,
    },
    snackbarSuccess: {
      text: this.$t('User saved successfully'),
      icon: 'mdi-check-circle',
      color: 'success'
    },
    user: {},
    passedCourses: []
  }},
  methods: {
    async show(user) {
      this.user = {...user, role: user ? user.role : 'user'}
      this.showDialog = true
      let events = await APIService.getUserEvents(this.user.id, "passedEvent")
      //console.log(events.results)

      for(let i=0; i<events.results.length; i++){
        if(events.results[i].lessonId != null && events.results[i].lessonId != ""){
          let c = {};
          c.id = events.results[i].lessonId
          let chapter = await APIService.getLesson(c)
          chapter.title = this.$t("Lesson")+": "+chapter.title;
          this.passedCourses.push(chapter);
        }else{
          let c = {};
          c.id = events.results[i].chapterId
          let chapter = await APIService.getChapter(c)
          chapter.title = this.$t("Chapter")+": "+chapter.title;
          this.passedCourses.push(chapter);
        }
      }
    },
    close () {
      this.showDialog = false
      this.user = {}
      this.$refs.userForm.resetValidation()
    },

  },
  computed: {
    ...mapGetters(['hasRole']),
    headers(){ return [
      { text: this.$t('Title'), value: 'title' }
        ]}
  },
  watch: {
    "user.email": function() {
      this.isEmailTaken = false
    },
  }
}
</script>

<style scoped>
.badge-image {
  max-width: 100px;
  padding: 4px;
}
.badge-image-small {
  max-width: 50px;
}
</style>
