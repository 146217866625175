<template>
  <v-row justify="center">
    <v-dialog
        v-model="showDialog"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('Team member') }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="userForm" lazy-validation>
            <v-container>
              <v-row>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                  <v-text-field
                      v-model="member.firstName"
                      :label="$t('firstName')"
                      required
                      :rules="requiredRule('Vorname')"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                  <v-text-field
                      v-model="member.lastName"
                      :label="$t('lastName')"
                      required
                      :rules="requiredRule('Nachname')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="member.email"
                      label="E-Mail"
                      required
                      :rules="emailRules()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            {{ $t('Cancel')}}
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveUser"
          >
            {{ member.id ? this.$t('Save') : this.$t('Add') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm-dialog ref="confirmDialog"/>
    <snackbar ref="snackbar"></snackbar>
  </v-row>
</template>

<script>
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/ConfirmDialog'
import Snackbar from '@/components/Snackbar'
import {mapState} from "vuex";
export default {
  name: 'MemberEditDialog',
  components: { Snackbar, ConfirmDialog },
  props: ['userId'],
  data: () => ({
    isEmailTaken: false,
    isLoginTaken: false,
    oldEmail: undefined,
    passwordRule: [
      value => (value && value.length >= 8) || this.$t('The password must have at least 8 characters'),
      value => (value && !!value.match(/\d/) && !!value.match(/[a-zA-Z]/)) || this.$t('The password hast to contain at least one character and one number')
    ],
    showDialog: false,
    showPassword: false,
    member: {},
  }),
  methods: {
    show (user) {
      this.member = { ...user }

      this.oldEmail = user?.email
      this.showDialog = true
    },
    close () {
      this.showDialog = false
      this.member = {}
      this.$refs.userForm.resetValidation()
    },
    async saveUser () {
      if (!this.$refs.userForm.validate()) {
        return
      }
      APIService.addTeamMember(this.userId, this.member).then(() => {
        this.$emit("updated", this.member)
        this.close()
        this.$refs.snackbar.show(this.snackbarSuccess)
      })
    },
    isEmailChanged() {
      return this.member.email !== this.oldEmail
    },
    requiredRule (fieldName) {
      return [value => !!value || `${fieldName} `+this.$t('mandatory')]
    },
    emailRules() {
      return [
        value => !!value || this.$t('E-Mail mandatory'),
        !this.isEmailTaken || this.$t('E-Mail already taken')
      ]
    },
  },
  computed: {
    ...mapState(['user']),
    snackbarSuccess() {
      return {
        text: this.$t('User saved successfully'),
            icon: 'mdi-check-circle',
          color: 'success'
      }
    }
  },
  watch: {
    "user.email": function() {
      this.isEmailTaken = false
    },
  }
}
</script>

<style scoped>

</style>
