<template>
    <v-row justify="center">
      <v-dialog
          v-model="showDialog"
          persistent
          max-width="1000px"
      >
        <v-card v-if="this.user">
          <v-card-title class="headline">
            {{ $t('Statistics')+": "+ (this.user ? this.user.id : "") }}
          </v-card-title>
          <v-row class="s3m-padding">
            <v-col>
              {{ this.formatLocaleDate(this.user.actualFromDate) + " - " + this.formatLocaleDate(this.user.actualToDate) }}<br>
              {{ $t('text-assing-stats-date-future') }}
            </v-col>
          </v-row>
          <v-list>
            <v-list-item-group color="primary">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t("User") }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                  {{ user.firstName + " " + user.lastName + " (" + user.email + ")" }}
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t('Session duration') }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                {{ getFormattedTime(this.user.sessionDurationTotal) }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t('Session duration')+" ("+$t("average")+")" }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                {{ getFormattedTime(this.user.sessionDurationAverage) }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t('timeout logouts')}}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-content>
                {{ this.user.timeoutLogouts }}
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-row class="s3m-padding">
            <v-col>
              <h3>{{ $t("Licenses") }}</h3>
              <v-data-table :headers="headers" :items="this.user.licenses" class="elevation-1">
                <template v-slot:item.id="{ item }">
                  {{ item.id }}
                </template>
                <template v-slot:item.owner="{ item }">
                  {{ item.owner.firstName+" "+item.owner.lastName }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row class="s3m-padding">
            <v-col>
              <h3>{{ $t("courses") }}</h3>
              <v-data-table :headers="headers_2" :items="this.user.courses" :sort-by="['sessionDuration']" :sort-desc="[true]" class="elevation-1">
                <template v-slot:item.id="{ item }">
                  {{ item.id }}
                </template>
                <template v-slot:item.sessionDuration="{ item }">
                  {{ getFormattedTime(item.sessionDuration) }}
                </template>
                <template v-slot:item.lastVisited="{ item }">
                  {{ formatLocaleDate(item.lastVisited) }}
                </template>
                <template v-slot:item.sessions="{ item }">
                  {{ item.sessions && item.sessions.length && item.sessions[0].duration ? getFormattedTime(item.sessions[0].duration) : '' }}
                </template>
                <template v-slot:item.completionDate="{ item }">
                  {{ formatLocaleDate(item.completionDate) }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer/>
            <v-btn
                color="primary"
                text
                @click="close"
            >
              {{ $t('Close') }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <Spinner v-if="showLoader" class="s3m-spinner" name="circle" color="#008bc4" />
      </v-dialog>
    </v-row>
</template>

<script>
import {
  calculateLoginSessionDuration,
  formatLocaleDate,
  groupedCourseEvents,
  groupedStudyProgress
} from "@/utils/common";
import APIService from "@/services/APIService";
import {calculateSessionDuration, calcUniqueCourseEvents, getFormattedTime} from "@/utils/common";

export default {
  name: 'UserStatsDialog',
  data() {
    return {
      courseEvents: null,
      studyProgress: null,
      uniqueCourseEvents: null,
      showLoader: false,
      user: null,
      showDialog: false,
      headers: [
        { text: this.$t('id'), value: 'id' },
        { text: this.$t('title'), value: 'product.name' },
        { text: this.$t('Owner'), value: 'owner' },
      ],
      headers_2: [
        { text: this.$t('id'), value: 'id' },
        { text: this.$t('title'), value: 'title' },
        { text: this.$t('Session duration')+" ("+this.$t('average')+")", value: 'sessionDuration' },
        { text: this.$t('last visited'), value: 'lastVisited' },
        { text: this.$t('last session duration'), value: 'sessions' },
        { text: this.$t('Completed'), value: 'completionDate'}
      ],
    }
  },
  methods: {
    formatLocaleDate,
    getFormattedTime,
    calculateMetrics() {
      this.user.courses.map(course => {
        course.completionDate = "";
        calculateSessionDuration('Course', course.id, course, this.groupedCourseEventsData);
        for (let courseEvent of this.courseEvents) {
          // Wenn die courseId des Events mit der aktuellen Kurs-ID übereinstimmt
          if(courseEvent.courseId === course.id) {
            // Weisen Sie dem Kurs das Datum des Events zu
            course.lastVisited = courseEvent.date;
            break;
          }
        }
        if (Array.isArray(this.studyProgress)) {
          for (let progress of this.studyProgress) {
            // Wenn die courseId und completionDate vorhanden sind, setzen Sie completionDate
            if (progress.course === course.id && progress.completionDate) {
              course.completionDate = progress.completionDate;
              break;
            }
          }
        }
        return course;
      });
      calculateLoginSessionDuration(this.user, this.groupedCourseEventsData);
      console.log(this.user);

      this.user.timeoutLogouts = this.courseEvents.filter(event => event.type === 'tologout').length;
    },
    show(user) {
      this.user = user ? {...user} : {}
      this.showDialog = true
      this.fetchData();
    },
    async fetchData(){
      this.showLoader = true;

      const [courseEvents, studyProgress, licenses, courses] = await Promise.all([
        APIService.getEvents('', this.user.id,'','', '', '', new Date(this.user.actualFromDate), new Date(this.user.actualToDate)),
        APIService.getStudyProgressUser(this.user.id, new Date(this.user.actualFromDate), new Date(this.user.actualToDate)),
        APIService.getUserLicences(this.user.id),
        APIService.getUserCourses(this.user.id)
      ]);

      this.courseEvents = courseEvents;
      this.studyProgress = studyProgress;
      this.user.licenses = licenses;
      this.user.courses = courses;

      this.uniqueCourseEvents = calcUniqueCourseEvents(this.courseEvents);
      this.calculateMetrics();
      this.user = { ...this.user };
      this.showLoader = false;
    },

    close() {
      this.showDialog = false
    }
  },
  computed: {
    groupedCourseEventsData(){
      return groupedCourseEvents(this.courseEvents);
    },
    groupedStudyProgressData(){
      return groupedStudyProgress(this.studyProgress);
    }
  },
}
</script>

<style scoped>
</style>
