<template>
  <div>
    <h1>{{ userId === $store.state.user.id ? $t('My Team') : $t('Team') }}</h1>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('Search')"
                single-line
                hide-details
            ></v-text-field>
          </v-col>
          <v-spacer/>
          <v-col class="text-right">
            <v-btn
                class="mr-2"
                rounded
                color="primary"
                dark
                @click="createUser"
            >
              <v-icon left>mdi-plus</v-icon>
              {{ $t('Team') }}
            </v-btn>
            <v-btn
                rounded
                color="primary"
                dark
                @click="importUsers"
            >
              <v-icon left>mdi-plus</v-icon>
              {{ $t('Import') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="filteredTeamMembers"
          :search="search"
          :items-per-page="itemsPerPage"
          @update:options="updateOptions"
          class="elevation-1"
          :hide-default-footer="filteredTeamMembers.length < 5"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              @click="deleteUser(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <member-edit-dialog ref="memberEditDialog" :userId="userId" @updated="reloadTeamMembers"/>
    <user-import-dialog ref="userImportDialog" @complete="importCompleted"/>
    <confirm-dialog ref="confirmDelete"/>
    <my-snack-bar ref="mySnackBar"/>
  </div>
</template>

<script>
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/ConfirmDialog'
import {mapState} from "vuex";
import {vuetifyTableMixin} from "@/mixins/VuetifyTableMixin";
import MemberEditDialog from "@/components/MemberEditDialog";
import UserImportDialog from "@/components/UserImportDialog";
import MySnackBar from "@/components/common/MySnackBar";
export default {
  name: "EmployeeList",
  mixins: [vuetifyTableMixin],
  components: {
    ConfirmDialog,
    MemberEditDialog,
    UserImportDialog,
    MySnackBar,
  },
  props: ['userId'],
  ...mapState(['user']),
  data() {
    return {
      search: '',
      teamMembers: [],
    }
  },
  methods: {
    createUser() {
      this.$refs.memberEditDialog.show()
    },
    deleteUser: function (member) {
      this.$refs.confirmDelete.show({
        title: this.$t('Remove member'),
        text: this.$t('Do you want to remove the following member?')+"<br><b>"+ member.firstName + " " + member.lastName +"</b>"+
            this.$t('All licences added to this member are deleted.'),
        confirm: this.$t('remove')
      }).then(() => {
        APIService.removeTeamMember(this.userId, member.id).then(async () => {
          this.teamMembers = await APIService.getTeamMembers(this.userId)
        })
      })
    },
    editUser (item) {
      this.$refs.memberEditDialog.show(item)
    },
    importUsers() {
      this.$refs.userImportDialog.show(this.userId)
    },
    reloadTeamMembers() {
      APIService.getTeamMembers(this.userId).then((members) => this.teamMembers = members)
    },
    importCompleted() {
      this.reloadTeamMembers()
      this.$refs.mySnackBar.success(this.$t('message_user_import_success'))
    }
  },
  computed: {
    ...mapState(['users', 'user']),
    filteredTeamMembers() {
      return this.teamMembers.filter(member => member.id !== this.user.id)
    },
    headers(){ return [
      { text: this.$t('firstName'), value: 'firstName' },
      { text: this.$t('lastName'), value: 'lastName' },
      { text: 'E-Mail', value: 'email' },
      { text: this.$t('Registered'), value: 'isRegistered' },
      {
        text: this.$t('Actions'),
        value: 'actions',
        sortable: false,
        align: 'end'
      }
    ]}
  },
  async beforeRouteEnter(to, from, next) {
    const teamMembers = await APIService.getTeamMembers(to.params.userId)
    next(vm => vm.teamMembers = teamMembers)
  },
  async beforeRouteUpdate(to,from,next) {
    this.teamMembers = await APIService.getTeamMembers(to.params.userId)
    next()
  }

}
</script>

<style scoped>

</style>
