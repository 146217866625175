<template>
  <v-snackbar
      v-model="show"
      :color="color"
      multi-line="multi-line"
      timeout="-1"
  >
    <v-layout align-center pr-4>
      <v-icon class="pr-3" dark large>{{icon}}</v-icon>
      <v-layout column>
        <div>{{message}}</div>
      </v-layout>
    </v-layout>
  </v-snackbar>
</template>

<script>
export default {
  name: "MySnackBar",
  data() {
    return {
      color: undefined,
      icon: undefined,
      message: '',
      show: false,
    }
  },
  methods: {
    success(message) {
      this.color = 'success'
      this.message = message
      this.show = true
      this.icon = 'mdi-checkbox-marked-circle'
    },
    error(message) {
      this.color = 'error'
      this.message = message
      this.show = true
      this.icon = 'mdi-alert-circle'
    }
  }
}
</script>

<style scoped>

</style>
