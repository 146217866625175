<template>
  <div>
    <h1>{{ $t('Statistics')+ " - " + $tc('Customer', 2) }}</h1>
    <v-card>
      <v-row>
        <v-col cols="12">
          {{ $t('From') }}:
          <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="formattedDate" label="" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="from" @input="menu = false; fetchData()"></v-date-picker>
          </v-menu>
          {{ $t('To') }}:
          <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="formattedToDate" label="" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="toDate" @input="menu2 = false; fetchData()"></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-card>
    <v-card>
      <v-card-text>
          <v-card-title @click="showData2 = !showData2">
            <div>{{ $t("Device Info") }}</div><v-spacer></v-spacer> <v-icon>{{ showData2 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-card-title>
        <v-expand-transition>
          <v-expansion-panels>
            <v-card-text v-show="showData2">
              <h3 class="expansion-panel-header">{{ $t("Browser") }}</h3>
              <v-expansion-panel>
                <v-expansion-panel-header>Browsers</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list-item-group>
                    <v-list-item v-for="(count, browser) in deviceHitlist.browsers" :key="browser">
                      <v-list-item-content>{{ browser }}: {{ count }}</v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <h3 class="expansion-panel-header">{{ $t("Devices") }}</h3>
              <v-expansion-panel v-for="(deviceData, device) in deviceHitlist.devices" :key="device">
                <v-expansion-panel-header>{{ device }}: {{deviceData.count}}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list>
                    <v-list-item v-for="(osData, os) in deviceData.os" :key="os">
                      <v-list-item-content>{{ os }}: {{ osData.count }}</v-list-item-content>
                      <v-list-item-group>
                        <v-list-item v-for="(browserCount, browser) in osData.browser" :key="browser">
                          <v-list-item-content>{{ browser }}: {{ browserCount }}</v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <h3 class="expansion-panel-header">{{ $t("OS") }}</h3>
              <v-expansion-panel v-for="(osData, os) in deviceHitlist.os" :key="os">
                <v-expansion-panel-header>{{ os }}: {{osData.count}}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list-item-group>
                    <v-list-item v-for="(browserCount, browser) in osData.browser" :key="browser">
                      <v-list-item-content>{{ browser }}: {{ browserCount }}</v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-card-text>
          </v-expansion-panels>
        </v-expand-transition>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-text>
        <v-card-title @click="showData = !showData">
          <div>{{ $t("Top Search Terms") }}</div><v-spacer></v-spacer> <v-icon>{{ showData ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-card-title>
        <v-expand-transition>
          <v-card-text v-show="showData">
            <div v-for="item in groupedSearchTerms.slice(0, 15)" :key="item.id">
              {{ item.value }}: {{ item.count }}
            </div>
          </v-card-text>
        </v-expand-transition>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-text>
        <v-card-title @click="showData1 = !showData1">
          <div>{{ $t("Top Filter Terms") }}</div><v-spacer></v-spacer> <v-icon>{{ showData1 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-card-title>
        <v-expand-transition>
          <v-card-text v-show="showData1">
            <div v-for="data in termCounts.slice(0, 15)" :key="data.id" style="display: flex;">
              <div style="font-family: monospace; width: 50px;">{{ data.count }}</div>
              <div>{{ data.filterTag.name }}</div>
            </div>
          </v-card-text>
        </v-expand-transition>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('Search')"
                single-line
                hide-details
            ></v-text-field>
          </v-col>
          <v-spacer/>
          <v-col class="text-right">
            <v-btn
                rounded
                color="success"
                dark
                @click="createCSV"
                style="margin-left:10px"
            >
              <v-icon left>mdi-download</v-icon>
              CSV
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="users"
          :search="search"
          :items-per-page="itemsPerPage"
          @update:options="updateOptions"
          @click:row="openUserStats"
          class="elevation-1"
          :hide-default-footer="users.length < 5"
          @current-items="getFiltered"
          ref="userTable"
          :footer-props="{
            'items-per-page-text':$t('Rows per page'),
            'items-per-page-options': [15, 50, 100, -1]
          }"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="editUser(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              class="mr-2"
              @click="deleteUser(item)"
          >
            mdi-delete
          </v-icon>
          <v-icon
              small
              @click="showLicenses(item)"
          >
            mdi-certificate
          </v-icon>
          <v-icon
              small
              @click="showPassed(item)"
          >
            mdi-check-bold
          </v-icon>
        </template>
        <template v-slot:item.lastLogin="{ item }" class="ml-0">
          {{ dateToLocaleString(item.lastLogin) }}
        </template>
        <template v-slot:item.role="{ item }" class="ml-0">
          <v-chip
              :color="item.role === 'admin' ?  'red' : item.role==='user' ? 'green' : 'blue'"
              class="ma-0"
              small
              style="color: white; width: 80px"
          >
            {{item.role}}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
    <user-stats-dialog ref="userStatsDialog"></user-stats-dialog>
    <user-edit-dialog ref="userEditDialog"/>
    <user-passed-dialog ref="userPassedDialog" />
    <user-license-dialog ref="userLicenseDialog" />
    <user-import-dialog ref="userImportDialog" @complete="importComplete"/>
    <user-migration-dialog ref="userMigrationDialog" @complete="migrationSuccess" @error="migrationError"/>
    <confirm-dialog ref="confirmDelete"/>
    <my-snack-bar ref="mySnackBar"/>
    <Spinner v-if="showLoader" class="s3m-spinner" name="circle" color="#008bc4" />
  </div>
</template>

<script>
import store from '@/store/index'
import UserEditDialog from '@/components/UserEditDialog'
import UserPassedDialog from "@/components/UserPassedDialog.vue";
import UserLicenseDialog from "@/components/UserLicenseDialog.vue";
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/ConfirmDialog'
import {vuetifyTableMixin} from "@/mixins/VuetifyTableMixin";
import {mapGetters, mapState} from "vuex";
import UserImportDialog from "@/components/UserImportDialog";
import UserMigrationDialog from "@/components/UserMigrationDialog";
import MySnackBar from "@/components/common/MySnackBar";
import Papa from "papaparse";
import UserStatsDialog from "@/components/UserStatsDialog.vue";
import localForage from "localforage";

export default {
  name: "StatisticsUsers",
  mixins: [vuetifyTableMixin],
  components: {
    UserStatsDialog,
    UserEditDialog,
    UserPassedDialog,
    UserLicenseDialog,
    UserImportDialog,
    UserMigrationDialog,
    ConfirmDialog,
    MySnackBar
  },
  data() {
    return {
      search: '',
      excludedUsers: 0,
      filteredUsers: [],
      showData: false,
      showData1: false,
      showData2: false,
      from: this.getInitialDate().toISOString().slice(0, 10),
      toDate: new Date(new Date().setDate(new Date().getDate())).toISOString().slice(0,10),
      menu: false,
      menu2: false,
      formattedDate: null,
      formattedToDate: null,
      loginEvents: [],
      filteredEvents: [],
      searchTerms: [],
      filterTerms: [],
      termCounts: [],
      deviceHitlist: {},
      showLoader: false
    }
  },
  methods: {
    openUserStats(user) {

      user.actualFromDate = this.from;
      let tempDate = new Date(this.toDate);
      tempDate.setDate(tempDate.getDate() + 1);
      user.actualToDate = tempDate.toISOString().slice(0, 10);

      this.$refs.userStatsDialog.show(user);
    },
    async fetchData(){
      this.showLoader = true;
      try{
        this.$store.commit('setDates', { startDate: this.from, endDate: this.toDate });

      } catch (error) {
        console.error("store commit exception: ", error);
      }

      let actualFromDate = new Date(this.from);
      let actualToDate = new Date(this.toDate);
      actualToDate.setDate(actualToDate.getDate() + 1);

      try {
        const storedData = await localForage.getItem('excludedUsers');
        this.excludedUsers = storedData ? JSON.parse(storedData) : [];
      } catch(err) {
        console.log(err);
        this.excludedUsers = [];
      }

      let promises = [
        APIService.getEvents('search', '', '','','', '', actualFromDate, actualToDate),
        APIService.getEvents('filter', '', '','','', '', actualFromDate, actualToDate),
        APIService.getEvents('LoginEvent', '', '','','', '', actualFromDate, actualToDate),
        APIService.getTags()
      ];

      if (!this.excludedUsers || this.excludedUsers.length === 0) {
        promises.push(APIService.getUsers());
      }

      const results = await Promise.all(promises);

      if (!this.excludedUsers || this.excludedUsers.length === 0) {
        // Der API-Aufruf an getUsers ist das letzte Ergebnis im Array
        const users = results[results.length - 1];

        const domainCheck = email => {
          const domains = ['christiani.de', 's3-medien.de', 'trigital.de'];
          return domains.some(domain => email.endsWith(domain));
        };

        // Filter users and save only ids
        this.excludedUsers = users.filter(user => domainCheck(user.email)).map(user => user.id);

        // Speichern Sie die neuen Daten in localStorage
        try {
          await localForage.setItem('excludedUsers', JSON.stringify(this.excludedUsers))
          //localStorage.setItem('excludedUsers', JSON.stringify(this.excludedUsers));
        }catch(err) {
          console.log(err);
        }
      }

      this.searchTerms = results[0];
      this.filterTerms = results[1];
      let login = results[2];
      let filterTags = results[3];

      login.forEach(function(item) {
        if (item.value) {
          try {
            item.deviceData = JSON.parse(item.value);
          } catch (error) {
            console.error('Handled - Invalid JSON in item value '+item.value+':', error);
            item.deviceData = null;
          }
        } else {
          item.deviceData = null;
        }
      });

      this.loginEvents = login;
      this.loginEvents = this.loginEvents.filter(event => !this.excludedUsers.includes(event.userId));

      this.termCounts = this.filterTerms.reduce((acc, obj) => {
        // find matching object in accumulation array
        let matchingObj = acc.find(a => a.value === obj.value);

        // if no matching object, add new object with count 1
        if (!matchingObj) {
          return [...acc, {value: obj.value, count: 1}];
        }

        // if matching object found, increase count
        matchingObj.count++;

        return acc;
      }, []);

      this.termCounts.sort((a, b) => b.count - a.count);
      this.termCounts.forEach(term => {
        let matchingTag = filterTags.find(tag => tag.id === term.value);
        if (matchingTag) {
          term.filterTag = matchingTag;
        }
      });

      let deviceHitlist = {
        devices: {},
        os: {},
        browsers: {}
      };

      this.loginEvents.forEach((loginEvent) => {
        if (loginEvent.deviceData && (!loginEvent.deviceData.browser || isNaN(loginEvent.deviceData.browser.name))) {

          let os = loginEvent.deviceData.os;
          let device = loginEvent.deviceData.device;
          let browser = loginEvent.deviceData.browser ? loginEvent.deviceData.browser.name : null;

          // Check total browsers
          if (browser) {
            deviceHitlist.browsers[browser] = (deviceHitlist.browsers[browser] || 0) + 1;
          }

          // Check total os
          if (os) {
            if (!deviceHitlist.os[os]) {
              deviceHitlist.os[os] = { count: 1, browser: {} };
            } else {
              deviceHitlist.os[os].count++;
            }

            // Check os-browser
            if (browser) {
              if (!deviceHitlist.os[os].browser[browser]) {
                deviceHitlist.os[os].browser[browser] = 1;
              } else {
                deviceHitlist.os[os].browser[browser]++;
              }
            }
          }

          // Check device
          if (device) {
            if (!deviceHitlist.devices[device]) {
              deviceHitlist.devices[device] = { count: 1, os: {} };
            } else {
              deviceHitlist.devices[device].count++;
            }

            // Check device-os
            if (os) {
              if (!deviceHitlist.devices[device].os[os]) {
                deviceHitlist.devices[device].os[os] = { count: 1, browser: {} };
              } else {
                deviceHitlist.devices[device].os[os].count++;
              }

              // Check device-os-browser
              if (browser) {
                if (!deviceHitlist.devices[device].os[os].browser[browser]) {
                  deviceHitlist.devices[device].os[os].browser[browser] = 1;
                } else {
                  deviceHitlist.devices[device].os[os].browser[browser]++;
                }
              }
            }
          }
        }
      });

      const sortObjectByCount = (obj) => {
        let sortedObject = {};

        Object.entries(obj)
            .sort(([,aData],[,bData]) => bData.count - aData.count)
            .forEach(([key, data]) => {
              // If data contain browsers, sort them
              if (data.browser) {
                sortedObject[key] = {
                  ...data,
                  browser: sortObjectByCount(data.browser)
                };
              } else {
                // Else, just add it to the sorted object
                sortedObject[key] = data;
              }
            })

        return sortedObject;
      }

      this.deviceHitlist = {
        devices: sortObjectByCount(deviceHitlist.devices),
        os: sortObjectByCount(deviceHitlist.os),
        browsers: sortObjectByCount(deviceHitlist.browsers)
      };

      // add last login to users
      this.users = this.users.map(user => {
        // Finde das erste LoginEvent, das zu diesem Benutzer gehört
        const loginEvent = this.loginEvents.find(event => event.userId === user.id);

        // Wenn ein LoginEvent gefunden wurde, füge das Datum zum user Objekt hinzu
        if (loginEvent) {
          user.lastLogin = loginEvent.date;
        }

        return user;
      });

      this.showLoader = false;

    },
    dateToLocaleString(date) {
      const parsedDate = new Date(date);

      if (isNaN(parsedDate.getTime())) {
        // Wenn das Datum ungültig ist, geben Sie den ursprünglichen String zurück
        return date;
      } else {
        return parsedDate.toLocaleDateString('de-DE');
      }
    },
    getInitialDate() {
      let date = new Date();
      date.setDate(date.getDate() - 28); // set the date to 4 weeks (28 days) ago
      return date;
    },
    getFiltered(){
      this.filteredUsers = this.$refs.userTable?.['$children'][0]?.filteredItems;
    },
    createCSV(){
      let csvData = [];
      csvData.push([]);
      for(let i=0; i < this.headers.length; i++){
        csvData[0].push(this.headers[i].text);
      }

      for(let i=0; i < this.filteredUsers.length; i++){
        let current = this.filteredUsers[i];
        let row = [current ? current.firstName : "", current ? current.lastName : "", current ? current.companyName : "", current && current.customerNumber ? current.customerNumber : "", current ? current.email : "", current ? current.role : "", ""];
        csvData.push(row);
      }
      let csv = Papa.unparse(csvData);

      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = 'user.csv';
      anchor.click();
    },
    createUser () {
      this.$refs.userEditDialog.show()
    },
    editUser (item) {
      this.$refs.userEditDialog.show(item)
    },
    showPassed (item){
      this.$refs.userPassedDialog.show(item);
    },
    showLicenses (item){
      this.$refs.userLicenseDialog.show(item);
    },
    deleteUser: function (user) {
      this.$refs.confirmDelete.show({
        title: this.$t('Remove user'),
        text: this.$t('Do you want to remove the following user?')+" "+user.firstName + " " + user.lastName,
        confirm: this.$t('remove')
      }).then(() => {
        APIService.deleteUser(user).then(() => {
          store.dispatch('fetchUsers')
        })
      })
    },
    importComplete() {
      this.$refs.mySnackBar.success(this.$t('Users successfully imported.'))
      store.dispatch('fetchUsers')
    },
    migrationSuccess() {
      store.dispatch('fetchUsers')
    },
    migrationError(error) {
      this.$refs.mySnackBar.error(this.$t('Import failed for some users') + ': ' + error.message)
      store.dispatch('fetchUsers')
    },
  },
  computed: {
    ...mapState(['user','users']),
    ...mapGetters(['hasRole']),
    groupedSearchTerms() {
      const counts = this.searchTerms.reduce((acc, termObj) => {
        if (acc.has(termObj.value)) {
          acc.get(termObj.value).count++;
        } else {
          acc.set(termObj.value, { ...termObj, count: 1 });
        }
        return acc;
      }, new Map());

      return Array.from(counts.values());
    },
    headers(){ return [
      { text: this.$t('firstName'), value: 'firstName' },
      { text: this.$t('lastName'), value: 'lastName' },
      {text: this.$t('Role'), value: "role"},
      {text: this.$t('last-login'), value: "lastLogin"},
      {
        value: 'completeName',
        align: ' d-none'
      }
    ]}
  },
  watch: {
    from: function(newDate) {
      this.formattedDate = this.dateToLocaleString(newDate);
    },
    toDate: function(newDate) {
      this.formattedToDate = this.dateToLocaleString(newDate);
    }
  },
  mounted() {
    if(this.$store.state.startDate && this.$store.state.endDate){
      this.from = this.$store.state.startDate;
      this.toDate = this.$store.state.endDate;
    }
    this.filteredUsers = this.users;
    this.fetchData();
    this.formattedDate = this.dateToLocaleString(this.from);
    this.formattedToDate = this.dateToLocaleString(this.toDate);
  },
  async beforeRouteEnter(to, from, next) {
    try {
      await store.dispatch('fetchUsers');
    } catch (error) {
      console.error("dispatch failed", error);
    }
    next();
  }
}
</script>

<style scoped>
.expansion-panel-header {
  text-align: left;
  margin: 10px;
}
</style>
