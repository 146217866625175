<template>
  <div>
    <h1>{{ $t('Statistics')+ " - " + $t('Licenses') }}</h1>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12">
            {{ $t('From') }}:
            <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="formattedDate" label="" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="from" @input="menu = false; datesChanged()"></v-date-picker>
            </v-menu>
            {{ $t('To') }}:
            <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="formattedToDate" label="" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="toDate" @input="menu2 = false; datesChanged()"></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-title>
      <v-row>
        <v-col>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('Search')"
              single-line
              hide-details
          ></v-text-field>
        </v-col>
        <v-spacer/>
        <v-col class="text-right">
          <v-btn
              rounded
              color="success"
              dark
              @click="createCSV"
              style="margin-left:10px"
          >
            <v-icon left>mdi-download</v-icon>
            CSV
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
      <v-data-table
          :headers="headers"
          :items="licences"
          @current-items="getFiltered"
          ref="licenceTable"
          :search="search"
          :items-per-page="itemsPerPage"
          @update:options="updateOptions"
          class="elevation-1"
          @click:row="openLicenseStats"
          :hide-default-footer="licences.length < 5"
          :footer-props="{
            'items-per-page-text':$t('Rows per page'),
            'items-per-page-options': [15, 50, 100, -1]
          }"
      >
        <template v-slot:item.dateOfPurchase="{item}">
          {{ item.dateOfPurchase | formatLocaleDate }}
        </template>
        <template v-slot:item.owner="{item}">
          {{ (item.owner ? item.owner.firstName : "") }} {{ (item.owner ? item.owner.lastName : "") }}
        </template>
        <template v-slot:item.lastUpdatedBy="{item}">
          {{ (item.lastUpdatedBy && item.lastUpdatedBy.firstName) ? item.lastUpdatedBy.firstName : "" }} {{ (item.lastUpdatedBy && item.lastUpdatedBy.lastName) ? item.lastUpdatedBy.lastName : "" }}
        </template>
        <template v-slot:item.quantityTotal="{item}">
          {{ (item.quantityTotal - item.quantityRemaining) }} / {{ item.quantityTotal }}
        </template>
        <template v-slot:item.expirationDate="{item}">
          <v-chip
              dark v-if="item.expirationDate"
              :color="isExpired(item.expirationDate) ?  'red' : 'green'"
          >
            {{ item.expirationDate | formatLocaleDate }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              v-if="!item.partner"
              small
              class="mr-2"
              @click="editLicenceAssignment(item)"

          >
            mdi-account-plus
          </v-icon>
        </template>
      </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <license-stats-dialog ref="licenseStatsDialog"></license-stats-dialog>
    <confirm-dialog ref="confirmDelete"/>
    <Spinner v-if="showLoader" class="s3m-spinner" name="circle" color="#008bc4" />
  </div>
</template>

<script>
import Papa from 'papaparse'
import ConfirmDialog from '@/components/ConfirmDialog'
import APIService from '@/services/APIService'
import {vuetifyTableMixin} from "@/mixins/VuetifyTableMixin";
import {isAfter, parseISO, startOfToday} from "date-fns";
import LicenseStatsDialog from "@/components/LicenseStatsDialog.vue";

export default {
  name: 'StatisticsLicenses',
  mixins: [vuetifyTableMixin],
  components: {LicenseStatsDialog, ConfirmDialog },
  data () {
    return {
      licences: [],
      filteredLicences: [],
      excludedUsers: 0,
      search: '',
      partners: [],
      showLoader: false,
      from: this.getInitialDate().toISOString().slice(0, 10),
      toDate: new Date(new Date().setDate(new Date().getDate())).toISOString().slice(0,10),
      formattedDate: null,
      formattedToDate: null,
      menu: false,
      menu2: false,
    }
  },
  computed:{
    headers(){ return [
      {
        text: this.$t('Name'),
        sortable: true,
        value: 'owner',
      },
      {
        text: this.$t('email'),
        sortable: true,
        value: 'owner.email',
        align: ' d-none'
      },
      {
        text: this.$t('Company'),
        sortable: true,
        value: 'owner.companyName',
      },
      {
        text: this.$t('Last modified from'),
        sortable: true,
        value: "lastUpdatedBy",
      },
      {
        text: this.$t('Partner'),
        sortable: true,
        value: 'partner.companyName',
      },
      {
        text: this.$t('Product number'),
        sortable: true,
        value: 'product.number',
      },
      {
        text: this.$t('Product'),
        value: 'product.name',
        sortable: false
      },
      {
        text: this.$t('Assigned'),
        value: 'quantityTotal',
        sortable: false,
        align: 'end'
      },
      {
        text: this.$t('License number'),
        value: 'id',
        sortable: false,
        align: ' d-none'
      }
    ]},
  },
  methods: {
    getFiltered(){
      this.$nextTick(() => {
        this.filteredLicences = this.$refs.licenceTable?.$children?.[0]?.filteredItems;
      })
    },
    openLicenseStats(license) {

      license.actualFromDate = this.from;
      let tempDate = new Date(this.toDate);
      tempDate.setDate(tempDate.getDate() + 1);
      license.actualToDate = tempDate.toISOString().slice(0, 10);

      this.$refs.licenseStatsDialog.show(license);
    },
    createCSV(){
      let csvData = [];
      csvData.push([]);
      for(let i=0; i < this.headers.length; i++){
        csvData[0].push(this.headers[i].text);
      }

      for(let i=0; i < this.filteredLicences.length; i++){
        let current = this.filteredLicences[i];
        let row = [current.owner ? current.owner.firstName : "", current.owner ? current.owner.lastName : "", current.owner ? current.owner.email : "", current.owner ? current.owner.companyName : "",  ((current.lastUpdatedBy && current.lastUpdatedBy.firstName) ? current.lastUpdatedBy.firstName : "") + " " + ((current.lastUpdatedBy && current.lastUpdatedBy.lastName) ? current.lastUpdatedBy.lastName : ""), current.partner ? current.partner.companyName : "", current.expirationDate, current.orderNumber, current.product ? current.product.number : "", current.product ? current.product.name : "", current.quantityTotal, current.dateOfPurchase, ""];
        csvData.push(row);
      }
      let csv = Papa.unparse(csvData);

      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = 'lizenzen.csv';
      anchor.click();
    },
    editItem(licence) {
      this.$refs.licencesEditDialog.edit({...licence})
    },
    editLicenceAssignment(licence) {
      this.$refs.licencesAssignDialog.edit({...licence})
    },
    isExpired(date) {
      if(!date) {
        return false;
      }
      return isAfter(startOfToday(),  parseISO(date))
    },
    datesChanged(){
      this.$store.commit('setDates', { startDate: this.from, endDate: this.toDate });

    },
    async fetchData() {
      this.showLoader = true;
      /*let actualFromDate = new Date(this.from);
      let actualToDate = new Date(this.toDate);
      actualToDate.setDate(actualToDate.getDate() + 1);*/

      this.licences = await APIService.getLicences()
      this.showLoader = false;

    },
    getInitialDate() {
      let date = new Date();
      date.setDate(date.getDate() - 28); // set the date to 4 weeks (28 days) ago
      return date;
    },
    dateToLocaleString(date) {
      return new Date(date).toLocaleDateString('de-DE');
    },
  },
  async beforeRouteUpdate(to,from,next) {
    //await this.fetchData()
    next()
  },
  async mounted() {
    if(this.$store.state.startDate && this.$store.state.endDate){
      this.from = this.$store.state.startDate;
      this.toDate = this.$store.state.endDate;
    }
    await this.fetchData();
    this.filteredLicences = this.licences;
    this.formattedDate = this.dateToLocaleString(this.from);
    this.formattedToDate = this.dateToLocaleString(this.toDate);
  },
  watch: {
    from: function(newDate) {
      this.formattedDate = this.dateToLocaleString(newDate);
    },
    toDate: function(newDate) {
      this.formattedToDate = this.dateToLocaleString(newDate);
    }
  },
}

</script>

<style lang="scss" scoped>
</style>
