<template>
  <v-row justify="center">
    <v-dialog
        v-model="showDialog"
        persistent
        max-width="1000px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $tc('Licenses', 1) }}</span>
        </v-card-title>
        <v-card-text>
          <div>{{user.firstName}} {{user.lastName}}</div>
          <v-form ref="userForm" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      :label="$t('Search')"
                      single-line
                      hide-details
                  ></v-text-field>
                <v-spacer/>
                  <v-data-table
                      :headers="headers"
                      :items="licenses"
                      :search="search"
                      :items-per-page="25"
                      class="elevation-1"

                      ref="userTable"
                      :footer-props="{
            'items-per-page-text':$t('Rows per page'),
            'items-per-page-options': [15, 50, 100, -1]
          }"
                  >
                    <template v-slot:item.expirationDate="{item}">
                      <v-chip
                          dark v-if="item.expirationDate"
                          :color="isExpired(item.expirationDate) ?  'red' : 'green'"
                      >
                        {{ item.expirationDate | formatLocaleDate }}
                      </v-chip>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm-dialog ref="confirmDialog"/>
    <snackbar ref="snackbar"></snackbar>
  </v-row>
</template>

<script>
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/ConfirmDialog'
import Snackbar from '@/components/Snackbar'
import {mapGetters} from "vuex";
import {isAfter, parseISO, startOfToday} from "date-fns";
export default {
  name: 'UserPassedDialog',
  components: { Snackbar, ConfirmDialog },
  data (){ return {
    showDialog: false,
    search: "",
    snackbarSuccessMail: {
      text: this.$t('User saved successfully')+". "+this.$t('An E-Mail was sent to the created user.'),
      icon: 'mdi-check-circle',
      color: 'success',
      timeout: 6000,
    },
    snackbarSuccess: {
      text: this.$t('User saved successfully'),
      icon: 'mdi-check-circle',
      color: 'success'
    },
    user: {},
    licenses: []
  }},
  methods: {
    async show(user) {
      this.user = {...user, role: user ? user.role : 'user'}
      this.showDialog = true
      this.licenses = await APIService.getUserAssignedLicences(this.user.id)
      console.log(this.licenses)
    },
    isExpired(date) {
      if(!date) {
        return false;
      }
      return isAfter(startOfToday(),  parseISO(date))
    },
    close () {
      this.showDialog = false
      this.user = {}
      this.$refs.userForm.resetValidation()
    }
  },
  computed: {
    ...mapGetters(['hasRole']),
    headers(){ return [
      { text: this.$t('Product'), value: 'product.name' },
      {
        text: this.$t('Order number'),
        sortable: true,
        value: 'orderNumber',
      },
      {
        text:  this.$t('Expiry Date'),
        sortable: true,
        value: 'expirationDate',
      }
        ]}
  },
  watch: {
    "user.email": function() {
      this.isEmailTaken = false
    },
  }
}
</script>

<style scoped>
.badge-image {
  max-width: 100px;
  padding: 4px;
}
.badge-image-small {
  max-width: 50px;
}
</style>
