<template>
    <v-row justify="center">
      <v-dialog
          v-model="showDialog"
          persistent
          max-width="1000px"
      >
        <v-card v-if="this.course">
          <v-card-title class="headline">
            {{ $t('Statistics')+": "+ (this.course ? this.course.title : "") }}
          </v-card-title>
          <v-list>
            <v-list-item-group color="primary">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t("totalViews") }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                  {{ course.allCount }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t("uniqueViews") }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                  {{ course.uniqueCount }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t("Completed") }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                  {{ course.coursesCompleted }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t("Not started") + " / " + $t("Started") }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                  {{ course.notStarted + " / " + course.started }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t("Session duration") + " (MM:SS)" }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                  {{ getFormattedTime(course.sessionDuration) }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t("last-viewed") }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                  {{ course.lastEventDate | formatLocaleDate }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t("Created on") }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                  {{ course.createdAt | formatLocaleDate }}
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>

          <div class="stats-chapters">
            <h3>{{ this.$t('Chapters') }}</h3>
            <div class="table-header table-row">
              <span class="table-cell title">{{ $t('title') }}</span>
              <span class="table-cell createdAt">{{ $t('Created on') }}</span>
              <span class="table-cell views">{{ $t('viewed') }}</span>
              <span class="table-cell uniqueViews">{{ $t('Unique') }}</span>
              <span class="table-cell uniqueViews">{{ $t('Session duration') }}</span>
            </div>
            <div v-for="chapter in course.chapters" :key="chapter.chapter.id" class="chapter">
              <div @click="toggleLessons(chapter.chapter.id)">
                <div class="table-row">
                  <h3 class="table-cell title">{{ chapter.chapter.title | removeBracketsContent }}</h3>
                  <span class="table-cell createdAt">{{ chapter.chapter.createdAt | formatLocaleDate }}</span>
                  <span class="table-cell views">{{ chapter.allCount }}</span>
                  <span class="table-cell uniqueViews">{{ chapter.uniqueCount }}</span>
                  <span class="table-cell sessionDuration">{{ getFormattedTime(chapter.sessionDuration) }}</span>
                </div>
              </div>
              <div v-if="activeChapter === chapter.chapter.id">
                <ul>
                  <li v-for="lesson in chapter.chapter.lessons" :key="lesson.lesson.id" class="lesson table-row">
                    <div class="table-cell title">{{ lesson.lesson.title | removeBracketsContent }}</div>
                    <div class="table-cell createdAt">{{ lesson.lesson.createdAt | formatLocaleDate }}</div>
                    <span class="table-cell views">{{ lesson.allCount }}</span>
                    <span class="table-cell uniqueViews">{{ lesson.uniqueCount }}</span>
                    <span class="table-cell sessionDuration">{{ getFormattedTime(lesson.sessionDuration) }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <v-card-actions>
            <v-spacer/>
            <v-btn
                color="primary"
                text
                @click="close"
            >
              {{ $t('Close') }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <Spinner v-if="showLoader" class="s3m-spinner" name="circle" color="#008bc4" />
      </v-dialog>
    </v-row>
</template>

<script>
import APIService from "@/services/APIService";
import {
  calculateSessionDuration,
  calcUniqueCourseEvents,
  groupedCourseEvents,
  groupedStudyProgress,
  getFormattedTime
} from "@/utils/common";

export default {
  name: 'CourseStatsDialog',
  data() {
    return {
      showLoader: false,
      course: null,
      showDialog: false,
      courseEvents: null,
      studyProgress: null,
      activeChapter: null,
      uniqueCourseEvents:  null,
      excludedUsers: null
    }
  },
  methods: {
    getFormattedTime,
    calculateCounts() {
      this.course.chapters.forEach(chapter => {
        chapter.allCount = this.calculateAllCount(chapter.chapter.id, 'openChapterEvent');
        chapter.uniqueCount = this.calculateUniqueCount(chapter.chapter.id, 'openChapterEvent');

        chapter.chapter.lessons.forEach(lesson => {
          lesson.allCount = this.calculateAllCount(lesson.lesson.id, 'openLessonEvent');
          lesson.uniqueCount = this.calculateUniqueCount(lesson.lesson.id, 'openLessonEvent');
        });
      });
    },
    calculateAllCount(id, type) {
      if(type === "openLessonEvent"){
        return this.courseEvents.filter(event => event.type === type && event.lessonId === id).length;
      }else if(type === "openChapterEvent"){
        return this.courseEvents.filter(event => event.type === type && event.chapterId === id).length;
      }
    },

    calculateUniqueCount(id, type) {
      if(type === "openLessonEvent") {
        const uniqueUserIds = Array.from(new Set(this.courseEvents.filter(event => event.type === type && event.lessonId === id).map(event => event.userId)));
        return uniqueUserIds.length;
      }else if(type === "openChapterEvent"){
        const uniqueUserIds = Array.from(new Set(this.courseEvents.filter(event => event.type === type && event.chapterId === id).map(event => event.userId)));
        return uniqueUserIds.length;
      }
    },
    calculateMetrics(calculateDeep = false) {
      if (!this.uniqueCourseEvents) return null;

      this.course.notStarted = 0;
      this.course.started = 0;
      this.course.coursesCompleted = 0;
      this.uniqueCourseEvents.forEach(event => {
        if (event.type === 'openCourseEvent') {
          const matchingUserProgress = this.groupedStudyProgressData[event.userId];
          if (matchingUserProgress && matchingUserProgress.some(progress => progress.completionDate)) {
            this.course.coursesCompleted++;
          }
          const userCourseEvents = this.groupedCourseEventsData[event.userId];
          if (userCourseEvents && userCourseEvents.some(userEvent => userEvent.type === 'openChapterEvent' || userEvent.type === 'openLessonEvent')) {
            this.course.started++;
          }
        }
      });
      this.course.notStarted = this.course.uniqueCount - this.course.started;
      calculateSessionDuration('Course', this.course.id, this.course, this.groupedCourseEventsData);
      if(calculateDeep){
        this.course.chapters.forEach(chapter => {
          calculateSessionDuration('Chapter', chapter.chapter.id, chapter, this.groupedCourseEventsData);

          chapter.chapter.lessons.forEach(lesson => {
            calculateSessionDuration('Lesson', lesson.lesson.id, lesson, this.groupedCourseEventsData);
          });
        });
      }
    },
    /*
    calculateSessionDuration(sessionType, ID, resultObject) {
      let sessionStartEvent = `open${sessionType}Event`;
      let sessionEndEvent = `closed${sessionType}Event`;

      let sessionProperty = `${sessionType.toLowerCase()}Id`;

      let sessionStart = null;
      let totalSessionDuration = 0;
      let sessionCount = 0;
      let sessions = [];

      Object.values(this.groupedCourseEvents).forEach(events => {
        events.forEach(event => {
          switch (event.type) {
            case sessionStartEvent:
              if (sessionStart && (!ID || event[sessionProperty] === ID)) {
                // Wenn es einen Start gibt, aber kein Ende und die ID übereinstimmt, nehmen wir das neue Startereignis.
                sessionStart = new Date(event.date).getTime();
              }
              sessionStart = new Date(event.date).getTime();
              break;

            case sessionEndEvent:
              if (sessionStart && (!ID || event[sessionProperty] === ID)) {
                // Wir berechnen die Sitzungsdauer in Sekunden.
                let sessionEnd = new Date(event.date).getTime();
                let durationInSeconds = (sessionEnd - sessionStart) / 1000;
                if (durationInSeconds <= 21600 && durationInSeconds > 0) {
                  sessions.push(durationInSeconds);
                  totalSessionDuration += durationInSeconds;
                  sessionCount++;
                }
                // Session abgeschlossen, zurücksetzen des Starts.
                sessionStart = null;
              }
              break;
          }
        });
      });

      // Speicherung der durchschnittlichen Sitzungsdauer und Sitzungen im bereitgestellten Objekt.
      resultObject.sessionDuration = (sessionCount > 0) ? totalSessionDuration / sessionCount : 0;
      resultObject.sessions = sessions;
    },*/
    show(course, excludedUsers) {
      this.excludedUsers = excludedUsers;
      this.course = course ? {...course} : {}
      this.showDialog = true
      this.fetchData();
    },
    toggleLessons(chapterId) {  // Add this method
      this.activeChapter = this.activeChapter === chapterId ? null : chapterId;
    },
    async fetchData(){
      this.showLoader = true;
      const courseEventsPromise = APIService.getEvents('', '',this.course.id,'', '', '', new Date(this.course.actualFromDate), new Date(this.course.actualToDate));
      const studyProgressPromise = APIService.getStudyProgress(this.course.id, new Date(this.course.actualFromDate), new Date(this.course.actualToDate));
      const coursePromise = APIService.getCourse(this.course, true);
      try {
        const [newCourseData, courseEvents, studyProgress] = await Promise.all([coursePromise, courseEventsPromise, studyProgressPromise]);
        this.courseEvents = courseEvents;
        this.courseEvents = this.courseEvents.filter(event => !this.excludedUsers.includes(event.userId));
        this.studyProgress = studyProgress;

        this.studyProgress = this.studyProgress.filter(event => !this.excludedUsers.includes(event.userId));
        this.studyProgress = this.studyProgress.filter(progress => progress.course === this.course.id);
        this.course = {
          ...this.course,
          ...newCourseData
        };

      } catch(error) {
        console.error(error);
      }
      this.uniqueCourseEvents = calcUniqueCourseEvents(this.courseEvents);
      this.calculateCounts();
      this.calculateMetrics(true);

      /*
      console.log(this.course);
      console.log(this.courseEvents);
      console.log(this.uniqueCourseEvents);
      console.log(this.studyProgress);
      console.log(this.groupedCourseEvents);
      console.log(this.groupedStudyProgress);
       */
      this.showLoader = false;
    },
    close() {
      this.showDialog = false
    }
  },
  computed: {
    groupedCourseEventsData(){
      return groupedCourseEvents(this.courseEvents);
    },
    groupedStudyProgressData(){
      return groupedStudyProgress(this.studyProgress);
    }
  },
}
</script>

<style scoped>
.stats-chapters{
  padding:20px;
}

.stats-chapters .table-row {
  display: flex;
  width: 100%;
}

.stats-chapters .table-cell {
  flex: 0 0 100%; /* Adjust this to your needs */
}

.stats-chapters .title {
  text-align: left;
  flex:0 0 50%;
}


.stats-chapters .table-cell.createdAt {
  text-align: left;
  flex:0 0 15%;
}

.stats-chapters .table-cell.views {
  text-align: center;
  flex:0 0 10%;
}

.stats-chapters .table-cell.uniqueViews {
  text-align: center;
  flex:0 0 10%;
}

.stats-chapters .table-cell.sessionDuration {
  text-align: center;
  flex:0 0 10%;
}

.stats-chapters .chapter {
  background-color: #f0f0f0; /* adjust this color to your liking */
}

.stats-chapters .lesson {
  background-color: #e0e0e0; /* adjust this color to your liking */
}
</style>
