<template>
  <div>
    <h1>{{ $t('Statistics')+ " - " + $t('courses') }}</h1>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12">
            {{ $t('From') }}:
            <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="formattedDate" label="" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="from" @input="menu = false; fetchData()"></v-date-picker>
            </v-menu>
            {{ $t('To') }}:
            <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="formattedToDate" label="" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="toDate" @input="menu2 = false; fetchData()"></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-title>
      <v-row>
        <v-col>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('Search')"
              single-line
              hide-details
          ></v-text-field>
        </v-col>
        <v-spacer/>
        <v-col class="text-right">
          <v-btn
              rounded
              color="success"
              dark
              @click="createCSV"
              style="margin-left:10px"
          >
            <v-icon left>mdi-download</v-icon>
            CSV
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          {{ $t("Excluded Users")+":"+(excludedUsers ? excludedUsers.length : 0) }} <br>
          <v-btn @click="fetchData(true)" color="primary">{{ $t('Reload excluded Users') }}</v-btn>
        </v-col>
      </v-row>
      <!--
<v-btn @click="showAll" color="primary">{{ $t('All') }}</v-btn>
-->
      <v-data-table
          :headers="headers"
          :items="this.courses"
          :search="search"
          @click:row="openCourseStats"
          :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50, 100] }"
          class="elevation-1"
      >
        <template v-slot:item.createdAt="{item}" class="ml-0">
          {{item.createdAt | formatLocaleDate }}
        </template>
        <template v-slot:item.lastEventDate="{item}" class="ml-0">
          {{item.lastEventDate | formatLocaleDate }}
        </template>
      </v-data-table>
    </v-card>
    <course-stats-dialog ref="courseStatsDialog"/>
    <Spinner v-if="showLoader" class="s3m-spinner" name="circle" color="#008bc4" />
  </div>
</template>

<script>

import APIService from "@/services/APIService";
import CourseStatsDialog from "@/components/CourseStatsDialog.vue";
import Papa from "papaparse";
import { formatLocaleDate } from '@/utils/common';
import localForage from "localforage";

export default {
  name: "Statistics",
  components: {CourseStatsDialog},
  props: ['userId'],
  data(){
    return{
      search: '',
      courseEvents: [],
      courses: [],
      from: this.getInitialDate().toISOString().slice(0, 10),
      toDate: new Date(new Date().setDate(new Date().getDate())).toISOString().slice(0,10),
      menu: false,
      menu2: false,
      formattedDate: null,
      formattedToDate: null,
      filteredEvents: [],
      showLoader: false,
      excludedUsers: 0,
      headers: [
        { text: this.$t('title'), value: 'title' },
        //{ text: 'ID', value: 'id' },
        { text: this.$t('viewed'), value: 'allCount' },
        { text: this.$t('Unique'), value: 'uniqueCount' },
        { text: this.$t('last-viewed'), value: 'lastEventDate'},
        { text: this.$t('Created on'), value: 'createdAt'}
      ],
    }
  },
  methods: {
    createCSV(){
      let csvData = [];
      csvData.push([]);
      for(let i=0; i < this.headers.length; i++){
        csvData[0].push(this.headers[i].text);
      }

      for(let i=0; i < this.courses.length; i++){
        let current = this.courses[i];
        let row = [current.title ? current.title : "", current.allCount ? current.allCount : "", current.uniqueCount ? current.uniqueCount : "", current.lastEventDate ? formatLocaleDate(current.lastEventDate) : "",  current.createdAt ? formatLocaleDate(current.createdAt) : ""];
        csvData.push(row);
      }
      let csv = Papa.unparse(csvData);

      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = 'lizenzen.csv';
      anchor.click();
    },
    openCourseStats(course) {
      course.actualFromDate = this.from;
      let tempDate = new Date(this.toDate);
      tempDate.setDate(tempDate.getDate() + 1);
      course.actualToDate = tempDate.toISOString().slice(0, 10);
      this.$refs.courseStatsDialog.show(course, this.excludedUsers);
    },
    async fetchData(reloadUsers = false) {
      this.showLoader = true;

      this.$store.commit('setDates', { startDate: this.from, endDate: this.toDate });

      let actualFromDate = new Date(this.from);
      let actualToDate = new Date(this.toDate);
      actualToDate.setDate(actualToDate.getDate() + 1);

      if(reloadUsers) {
        localStorage.removeItem('excludedUsers');
      }
      try {
        const storedData = await localForage.getItem('excludedUsers');
        this.excludedUsers = storedData ? JSON.parse(storedData) : [];
      } catch(err) {
        console.log(err);
        this.excludedUsers = [];
      }
      /*
      let storedData = localStorage.getItem('excludedUsers');
      this.excludedUsers = storedData ? JSON.parse(storedData) : null;*/

      let promises = [
        APIService.getCourses(),
        APIService.getEvents('openCourseEvent', '', '', '', '', '', actualFromDate, actualToDate)
      ];

      if (!this.excludedUsers || this.excludedUsers.length === 0) {
        promises.push(APIService.getUsers());
      }

      const results = await Promise.all(promises);

      if (!this.excludedUsers || this.excludedUsers.length === 0) {
        // Der API-Aufruf an getUsers ist das letzte Ergebnis im Array
        const users = results[results.length - 1];

        const domainCheck = email => {
          const domains = ['christiani.de', 's3-medien.de', 'trigital.de'];
          return domains.some(domain => email.endsWith(domain));
        };

        // Filter users and save only ids
        this.excludedUsers = users.filter(user => domainCheck(user.email)).map(user => user.id);

        // Speichern Sie die neuen Daten in localStorage
        try {
          await localForage.setItem('excludedUsers', JSON.stringify(this.excludedUsers))
          //localStorage.setItem('excludedUsers', JSON.stringify(this.excludedUsers));
        }catch(err) {
          console.log(err);
        }
      }

      this.courses = results[0];
      this.courseEvents = results[1];
      this.filteredEvents = [...results[1]];
      this.filteredEvents = this.filteredEvents.filter(event => !this.excludedUsers.includes(event.userId));
      this.addCourseEventData();
      this.courses = this.sortedCourses(this.courses);
      this.showLoader = false;


    },
    addCourseEventData() {
      this.courses = this.courses.map(course => {
        //const count = this.filteredEvents.filter(event => event.courseId === course.id).length;
        const courseEventsForThisCourse = this.filteredEvents.filter(event => event.courseId === course.id);

        const allCount = courseEventsForThisCourse.length;

        const uniqueUserIds = new Set(courseEventsForThisCourse.map(event => event.userId));
        const uniqueCount = uniqueUserIds.size;
        const lastEvent = this.filteredEvents.find(event => event.courseId === course.id);
        const lastEventDate = lastEvent ? lastEvent.date : null;
        return {...course, allCount, uniqueCount, lastEventDate};
      });
    },
    showAll(){
      this.filteredEvents = [...this.courseEvents];
      this.addCourseEventData();
      this.courses = this.sortedCourses(this.courses);

    },
    showUnique() {
      let uniqueEvents = [];

      this.courseEvents.forEach(event => {
        if (!uniqueEvents.some(uniqueEvent =>
            uniqueEvent.courseId === event.courseId && uniqueEvent.userId === event.userId)) {
          uniqueEvents.push(event);
        }
      });

      this.filteredEvents = uniqueEvents;
      this.addCourseEventData();
      this.courses = this.sortedCourses(this.courses);
    },
    sortedCourses(courses) {
      // sortieren Sie this.courses nach der 'count' Eigenschaft in absteigender Reihenfolge
      return [...courses].sort((a, b) => b.allCount - a.allCount);
    },
    dateToLocaleString(date) {
      return new Date(date).toLocaleDateString('de-DE');
    },
    getInitialDate() {
      let date = new Date();
      date.setDate(date.getDate() - 28); // set the date to 4 weeks (28 days) ago
      return date;
    },
  },
  watch: {
    from: function(newDate) {
      this.formattedDate = this.dateToLocaleString(newDate);
    },
    toDate: function(newDate) {
      this.formattedToDate = this.dateToLocaleString(newDate);
    }
  },
  mounted() {
    if(this.$store.state.startDate && this.$store.state.endDate){
      this.from = this.$store.state.startDate;
      this.toDate = this.$store.state.endDate;
    }
    this.formattedDate = this.dateToLocaleString(this.from);
    this.formattedToDate = this.dateToLocaleString(this.toDate);
    this.fetchData();
  }
}


</script>

<style scoped>

</style>
