<template>
  <v-dialog
      v-if="showDialog"
      v-model="showDialog"
      persistent
      width="1024"
      max-width="1024"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Daten-Migration</span>
      </v-card-title>
      <v-card-text>
        <v-file-input
            show-size
            v-model="importCSV"
            label="Migration CSV"
            @change="readUserCSV"
        ></v-file-input>
        <template  v-if="importCSV">
          <v-data-table
              :headers="headers"
              :items="allUsers"
              v-model="selectedMigrateUsers"
              :items-per-page="-1"
              class="elevation-1"
              :hide-default-footer="true"
              show-select
              @toggle-select-all="selectAllToggle"
              item-key="key"
          >
            <template v-slot:item.password="{ item }">
              <div :class="{valid: item.invalid }">{{item.password}}{{ item.invalid ? ` (${item.invalid })` : ''}}</div>
            </template>
            <template v-slot:item.data-table-select="{ item, isSelected, select }">
              <v-simple-checkbox
                  :value="isSelected"
                  :readonly="!!item.invalid"
                  :disabled="!!item.invalid"
                  @input="select($event)"
              ></v-simple-checkbox>
            </template>
          </v-data-table>
          {{ selectedMigrateUsers.length === 0 ? 'Kein' : selectedMigrateUsers.length }} Benutzer für Migration ausgewählt
        </template>
      </v-card-text>
      <v-card-actions>
        <v-btn
            color="blue darken-1"
            text
            @click="close"
        >
          {{ $t('Close') }}
        </v-btn>
        <v-btn
            :disabled="selectedMigrateUsers.length === 0"
            color="blue darken-1"
            text
            @click="importUsers"
        >
          Daten migrieren
        </v-btn>
        <v-btn
            :disabled="!data"
            color="blue darken-1"
            text
            @click="download()"
        >
          Download Log
        </v-btn>
      </v-card-actions>
    </v-card>
    <my-snack-bar ref="mySnackBar"/>
  </v-dialog>
</template>

<script>
import Papa from 'papaparse'
import {mapState} from "vuex";
import APIService from "@/services/APIService";
import MySnackBar from "@/components/common/MySnackBar";
export default {
  name: "UserMigrationDialog",
  components: {MySnackBar},
  data() {
    return {
      data: undefined,
      allUsers: [],
      importCSV: undefined,
      selectedMigrateUsers: [],
      showDialog: false,
    }
  },
  methods: {
    show() {
      this.showDialog = true
    },
    close() {
      this.allUsers = []
      this.selectedMigrateUsers = []
      this.importCSV = undefined
      this.showDialog = false
    },
    async readUserCSV() {
      const that = this
      Papa.parse(this.importCSV, {
        header: true,
        skipEmptyLines: true,
        complete(result) {
          let allUsers = result.data.map(user => ({...user,  key: `${user.orderNumber}_${user.productNumber}`}))
          that.allUsers = [...new Map(allUsers.map((user) => [`${user.orderNumber}_${user.productNumber}`, user])).values()]
        }
      })
    },
    async importUsers() {
      // eslint-disable-next-line no-unused-vars
      await APIService.migrateUsers(this.selectedMigrateUsers.map(({key, ...keepAttrs}) => keepAttrs))
          .then((data) => {
            this.data = data
            this.$refs.mySnackBar.success(this.$t('Users successfully migrated.'))
            this.$emit('complete')
          })
          .catch((error) => {
            this.$emit('error', error.response.data)
          });
      this.selectedMigrateUsers = []
    },
    download() {
      // credit: https://www.bitdegree.org/learn/javascript-download
      let text = JSON.stringify(this.data);
      let filename = 'migration-log.txt';
      let element = document.createElement('a');
      element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(text));
      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();
      document.body.removeChild(element);
    },
    selectAllToggle(props) {
      if(this.selectedMigrateUsers.length !== this.allUsers.length) {
        this.selectedMigrateUsers = [];
        const self = this;
        props.items.forEach(item => {
          if(!item.invalid) {
            self.selectedMigrateUsers.push(item);
          }
        });
      } else this.selectedMigrateUsers = [];
    },
  },
  computed: {
    ...mapState(['options']),
    itemsPerPage() {
      return this.options.itemsPerPage
    },
    headers(){ return [
      { text: this.$t('orderNumber'), value: 'orderNumber', sortable: false },
      { text: this.$t('customerNumber'), value: 'customerNumber', sortable: false },
      { text: this.$t('companyName'), value: 'companyName', sortable: false},
      { text: this.$t('dateOfPurchase'), value: 'dateOfPurchase', sortable: false },
      { text: 'Menge', value: 'quantity', sortable: false },
      { text: 'E-Mail', value: 'email', sortable: false },
      { text: 'productNumber', value: 'productNumber', sortable: false },
    ]},
  },
}
</script>

<style scoped>
.valid {
  color: red;
}
</style>